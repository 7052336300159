<template>
  <div class="maintenance">
    <div class="maintenance__content">
      <div class="maintenance__title">
        <h1>Creando mas y mejores dulces!</h1>
        <h2>Pronto estaremos de vuelta</h2>
      </div>
      <div class="maintenance__content__logo">
        <img
          src="@/assets/images/dragon-bg.webp"
          alt="maintenance dragon logo"
        />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2?family=Emilys+Candy&display=swap");

.maintenance {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: linear-gradient(to bottom, #7c4dff 0%, #9a00ff 100%);
  font-family: "Emilys Candy", cursive;
  color: #fff;

  &__title {
    text-align: center;
  }

  &__content__logo {
    text-align: center;

    img {
      width: 90%;
      height: auto;
    }
  }
}
</style>
