<template>
  <div class="main">
    <router-view />
  </div>
</template>

<style lang="scss">
body,
html {
  height: 100%;
  margin: 0;
  padding: 0;
}

* {
  box-sizing: border-box;
}

#app,
.main {
  height: 100%;
}
</style>
